<template>
    <transition name="modal">
    <div class="modal-mask">
        <div class="modal-wrapper">
        <div class="modal-container">

            <div class="modal-header">
            <slot name="header">
                default header
            </slot>
            </div>

            <div class="modal-body">
            <slot name="body">
                default body
            </slot>
            </div>

            <div class="modal-footer">
            <slot name="footer">
                default footer                
            </slot>
            </div>

            <button class="modal-default-button" @click="$emit('close')" />
        </div>
        </div>
    </div>
    </transition>
</template>



<script>

export default {
  name: 'Modal'
}
</script>



<style lang="scss">

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;  
}

.modal-container {
  max-height: calc(100vh - 50px);
  
  width: 300px;
  margin: 0px auto;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: $borderradius;
  box-shadow: $shadowM;
  transition: all 0.3s ease;
  font-family: Roboto, Arial, sans-serif;
  position: relative;
}

.modal-header h3 {
  margin-top: 0;
  color: black;
  font-size: 28px;
  font-weight: normal;
}

.modal-body {
  margin: 20px 0;
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}

.modal-default-button {
  position: absolute;
  right: 30px;
  top: 39px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: none;
  background-image: url("../assets/close-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;

  &:hover{
      opacity: 0.8;
  }
}

// transitions
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
