<template>
    <div class="sidebar" :class="{collapsed: $store.state.sidebarCollapsed}">
      <div class="toggle" @click="toggle">   
        <span>&laquo;</span>{{$store.state.sidebarCollapsed ? '' : ' hide'}}  
      </div>
      <div class="sidebar-content">
        <router-link v-if="$store.getters.isAdmin || $store.getters.isProfessor || $store.getters.isOrgAdmin" to="/dashboard">Dashboard</router-link>      
        <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" to="/users">Users</router-link>
        <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin || $store.getters.isProfessor" to="/sessions">Sessions</router-link>
        <router-link v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" to="/devices">Devices</router-link>        
        <router-link v-if="$store.getters.isAdmin" to="/authoring">Authoring</router-link>
        <router-link v-if="$store.getters.isAdmin" to="/library">Content library</router-link>

        <slot name="body">
            
        </slot>
      </div>
    </div>
</template>



<script>

export default {
  name: 'Sidebar',
  methods: {
    toggle() {     
      this.$store.commit('toggleSidebar');      
    }  
  }
}
</script>

<style lang="scss">

.sidebar{
    //float: left;  
    //width: 250px;  
    min-width: 250px;    
    //padding: 20px 0;
    flex: none;
    background-color: #F8F9FB;

    .toggle{
      font-size: 10px;
      height: 25px;
      line-height: 25px;
      text-align: right;
      padding-right: 10px;
      cursor: pointer;
      color: $october;

      &:hover{
        background-color: #E9F4EA;
      }

      span{
        display: inline-block;
        transform: scale(1.5);
      }
    }

    &.collapsed{
      width: 25px;
      min-width: 0; 
      
      .toggle{
        height: 100%;
      }

      span{
        transform: scale(1.5) rotate(180deg);
      }

      .sidebar-content{
        width: 0;
        overflow: hidden;
      }          
    }

    .tree{
      margin-top: 0;
    }

    a{
      display: block;
      height: 28px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
      padding-left: 25px;

      &.router-link-active{
        background-color: #E2E2E2;
        border-radius: 0;
      }
    }
}

</style>
